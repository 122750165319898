import { v4 as uuidv4} from 'uuid';

const dataSlider = [
    {
        id: uuidv4(),
        title: 'Akril Medijapan Visoki Sjaj',
        subTitle: 'Kuhinja sa pultom, akril medijpan visoki sjaj sa urezanim rukohvatima u aktuelnoj bijeloj boji',
        backgroundColor: '#dfdfdf',
        width: '80%',
        color: 'black',
        fontFamily: 'Roboto, sans-serif',
    },
    {
        id: uuidv4(),
        title: 'Medijapan Visoki Sjaj',
        subTitle: 'Sa urezanim rukohvatima u finoj kombinaciji fronti bijela visoki sjaj i cappuccino radne ploče i zidne obloge',
        backgroundColor: '#97623d',
        width: '80%',
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
    },
    {
        id: uuidv4(),
        title: 'Medijapan',
        subTitle: 'Jos jedna impozantna kuhinja u prekrasnom ambijentu koja odiše pažljivo izabranom kombinacijom crne i  zlatnog hrasta ',
        backgroundColor: 'black',
        width: '80%',
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
    },
    {
        id: uuidv4(),
        title: 'Medijapan Visoki Sjaj',
        subTitle: 'Prekrasna kuhinja medijapan visoki sjaj naše izrade po vašoj mjeri i želji, u skladnom spoju uvijek aktualnih bijelo-sivih nijansi',
        backgroundColor: '#868B8E',
        width: '80%',
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
    },
    {
        id: uuidv4(),
        title: 'Farbani Medijapan',
        subTitle: 'Farbani medijapan sa frezanim rukohvatima u atraktivnoj antricit boji sa bambus kontrukcijom i ukrasnim lajsnama',
        backgroundColor: '#122620',
        width: '80%',
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
    },
    {
        id: uuidv4(),
        title: 'Medijapan Visoki Sjaj',
        subTitle: 'Divna kuhinja rađena po mjeri u crveno bijeloj kombinaciji sa ukrasnim lajsnama i šankom',
        backgroundColor: '#F51720',
        width: '80%',
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
    },
    {
        id: uuidv4(),
        title: 'Akril Medijapan Visoki sjaj',
        subTitle: 'Akril medijapan visoki sjaj u bijeloj boji bez ručkica sa mehanizmom otvaranja na potisak',
        backgroundColor: '#A47551',
        width: '80%',
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
    },
    {
        id: uuidv4(),
        title: 'Akril Medijapan Visoki Sjaj',
        subTitle: 'Akril medijapan visoki sjaj u bordo boji sa bež ladicama i ukrasnim lajsnama radjena po mjeri',
        backgroundColor: '#870A30',
        width: '80%',
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
    },
    {
        id: uuidv4(),
        title: 'Akril Medijapan Visoki Sjaj',
        subTitle: 'Akril medijapan visoki sjaj, crno bijela sa sankom i ukrasnim lajsnama radjena po mjeri',
        backgroundColor: '#edcf9c',
        width: '80%',
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
    },
    {
        id: uuidv4(),
        title: 'San Remo/Vanilija sa pultom',
        subTitle: 'Kuhinja od univera u kombinaciji dezena San Remo/Vanilija sa pultom i ukrasnim lajsnama',
        backgroundColor: '#715e4f',
        width: '80%',
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
    },
    {
        id: uuidv4(),
        title: 'Farbani Medijapan',
        subTitle: 'Divna vintage kuhinja mint zelena kombinacija sa retro ručkicama, farbani medijapan u našoj izradi  po mjeri i želji naših zadovoljnih kupaca',
        backgroundColor: '#b6d7a8',
        width: '80%',
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
    },
]

export default dataSlider;