const reviewData = [
    {
      id: 1,    
      name: "Person 1",
      review: "lorasdasdasd asda sdas dasdkasdk jaskd asdkjas kdj",
      backgroundColor: '#508991',
    },
    {
        id: 2,  
        name: "Person 2",
        review: "lorasdasdasd asda sdas dasdkasdk jaskd asdkjas kdj",
        backgroundColor: '#004346',
    },
    {
        id: 3,  
        name: "Person 3",
        review: "lorasdasdasd asda sdas dasdkasdk jaskd asdkjas kdj",
        backgroundColor: '#19647E',
    },
    {
        id: 4,  
        name: "Person 4",
        review: "lorasdasdasd asda sdas dasdkasdk jaskd asdkjas kdj",
        backgroundColor: '#A5C4D4',
    },
]

export default reviewData;